import React, { Fragment, useState, useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
// import ref from '../content/contacts/contacts.json';
import Loader from 'react-loader';

export default function Contacts(props) {
    const [ref, setRef] = useState();

    useEffect(() => {
      fetch("./content/contacts/contacts.json")
        .then(response => 
            response.json()
        )
        .then(data  => {
            setRef(data);
        });
    }, [])
    if (ref === undefined) {
        return <Loader></Loader>;
    }

    let phone = `tel:${ref.phone}`;
    let email1 = `mailto:${ref.email[0]}`;
    let email2 = `mailto:${ref.email[1]}`;

    return (
        <Fragment>
            <Header history={props.history}></Header>
            <main className="container">
                <div className="row">
                    <div className="col">
                        <section className="top-sales">
                            <h2 className="text-center">{ref.header}</h2>
                            <br></br>
                            <p>Location – {ref.location}</p>
                            <p>Phone: <a href={phone}>{ref.phone}</a></p>
                            <p>E-mail: <a href={email1}>{ref.email[0]}</a>, <a href={email2}>{ref.email[1]}</a></p>
                            <br></br>
                            <p>{ref.text[0]}</p>
                            <p>{ref.text[1]}</p>
                            <p>{ref.text[2]}</p>
                        </section>
                    </div>
                </div>
            </main>
            <Footer history={props.history}></Footer>
        </Fragment>
    )
}
