import React, { Fragment, useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import ChansTeam from '../img/chans-team.jpg';
import ChansTeam2 from '../img/techcellence.jpg';
import parse from 'react-render-html'
import Loader from 'react-loader';

export default function Story(props) {
    const [ref, setRef] = useState();
    useEffect(() => {
      fetch("./content/about/about.json")
        .then(response => 
            response.json()
        )
        .then(data  => {
            setRef(data);
        });
    }, [])
    if (ref === undefined) {
        return <Loader></Loader>;
    }
    return (
        <Fragment>
            <Header history={props.history}></Header>
            <main className="container">
                <div className="row">
                    <div className="col">
                        <section className="top-sales">
                            <h2 className="text-center">{ref.header}</h2>
                            {parse(ref.text)}
                        </section>
                    </div>
                </div>
            </main>
            <Footer history={props.history}></Footer>
        </Fragment>
    )
}
