import React, { Fragment } from 'react';
import buttonComfortable from '../img/comf.png';
import buttonTrend from '../img/trend.png';
import buttonOffice from '../img/office.png';

export default function Work(props) {

    return (
        <Fragment>
            <main className="work-container" id="work">
                <div className="wrap block">
                    <div className="row big-row">
                        <div className="col">
                            <h2 className="h2-black">Which Do You Prefer?</h2>
                        </div>
                    </div>
                </div>
            </main>
        </Fragment>
    )
}
