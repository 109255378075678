import React, { Fragment, useState, useEffect } from 'react';

// import ref from '../content/main/main.json';
import parse from 'react-render-html'
import Loader from 'react-loader';

export default function About(props) {
    const [ref, setRef] = useState();
    useEffect(() => {
      fetch("./content/main/main.json")
        .then(response => 
            response.json()
        )
        .then(data  => {
            setRef(data);
        });
    }, [])
    if (ref === undefined) {
        return <Loader></Loader>;
    }
    return (
        <Fragment>
            <main className="container" id="about">
                <div className="wrap block">
                    <div className="row">
                        <div className="col">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-inline">
                            <div className="line-ver"></div>
                            <p>
                                {parse(ref.text)}
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </Fragment>
    )
}
