import React, { Fragment, useState, useEffect } from 'react';
import About from './About';
import Header from './Header';
import Work from './Work';
import Feedback from './Feedback';
import Footer from './Footer';
// import ref2 from '../content/references/references.json';
import Loader from 'react-loader';

export default function Main(props) {
    const [ref2, setRef] = useState();
    useEffect(() => {
      fetch("./content/references/references.json")
        .then(response => 
            response.json()
        )
        .then(data  => {
            setRef(data);
        });
    }, [])
    if (ref2 === undefined) {
        return <Loader></Loader>;
    }
    return (
        <Fragment>
            <Header history={props.history}></Header>
            <About history={props.history}></About>
            <Work history={props.history}></Work>
            <Feedback history={props.history} content={ref2} h2={"Reviews"}></Feedback>
            <Footer history={props.history}></Footer>
        </Fragment>
    )
}
